// 搜索词页面
import request from '@/utils/request'

//  搜索词查询初始化
export function getPageInit(id) {
   return request({
     url: "/api/searchword/pageInit",
     method: "get",
     headers:{
      'profileId': id
     }
   })
}

// 查询分页 
export function getListPage(params) {
  return request({
    url: "/api/searchword/listPage",
    params,
    method: "get"
  })
}

// 新增关键词--否定关键词
export function increaseKeywords(data, profileId) {
  return request({
    url: "/api/searchword/add/keywords",
    data,
    headers: {
      "profileId": profileId
    },
    method: "post"
  })
}

// 新增商品定位--否定产品订单
export function addTargets(data, profileId) {
  return request({
    url: "/api/searchword/add/targets",
    data,
    headers: {
      "profileId": profileId
    },
    method: "post"
  })
}

// 查询搜索词(keyword)使用情况
export function getListKeywordExist(params) {
  return request({
    url: "/api/searchword/listKeywordExist",
    params,
    method: "get"
  })
}


// 查询搜索词(target)使用情况
export function getListTargetExist(params) {
  return request({
    url: "/api/searchword/listTargetExist",
    params,
    method: "get"
  })
}

// 修改竞价通用方法
export function modifyBid(data, profileId) {
  return request({
    url: "/api/AmzAdCampaigns/updateBid",
    data,
    headers: {
      "profileId": profileId
    },
    method: "put"
  })
}

// 根据关键词获取建议竞价
export function getKeywordBidRecommendations(keywordId, profileId) {
  return request({
    url: "/api/ad/keyword/getKeywordBidRecommendations/" + keywordId,
    method: "get",
    headers: {
      "profileId": profileId
    },
  })
}

// 表现明细查询
export function listByQuery(params) {
  return request({
    url: "/api/searchword/listByQuery",
    params,
    method: "get"
  })
}

// 根据关键词Text 获取建议竞价
export function getKeywordBidRecommendationsByText(data, profileId) {
  return request({
    url: "/api/ad/keyword/getKeywordBidRecommendations",
    data,
    method: "post",
    headers: {
      "profileId": profileId
    },
  })
}