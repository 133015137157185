<template>
  <!-- 搜索词 -->
  <div 
    class="storage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div class="append">
      <div class="seach">
        <el-select
          placeholder="请选择店铺" 
          class="el-select-select"
          v-model="pageInfoData.profileId"
         >
           <el-option-group
             :key="group.id"
             :label="group.name"
             v-for="group in shopLists"
             >
              <el-option v-for="item in group.marketPlaceTree"
                :key="item.profileId"
                :label="group.name+'-'+item.name"
                :value="item.profileId"
                @click.native="checkGroup(group.id, item.id, item.profileId,item.currency)"
                >{{ item.name }}
              </el-option>
           </el-option-group>
        </el-select>
        <TimeQuantum
          style="margin-right:10px" 
          @selectFinish='topTime'
          section="近7天"
          @sectionFinish='sectionSure'
        ></TimeQuantum>
        <el-select
          clearable
          filterable
          placeholder="请输入后/直接选择组合"
          v-model="pageInfoData.adCombination"
          class="el-select-select"
          @clear="emptyEvent"
          @change="find"
         >
          <el-option
            v-for="item in portfoliosList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="onClickEvent(item.id)"
          >{{item.value}}
          </el-option>
        </el-select>
        <el-select
          clearable
          filterable
          placeholder="请输入后/直接选择广告活动"
          v-model="pageInfoData.adActivity"
          class="el-select-select"
          @clear="remove_Event"
          @change="find"
         >
          <el-option
            v-for="item in adVertisCampaignList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="select_Event(item.id)"
          >
          <p>
            <span>({{item.type}})</span>-
            <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
            <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
            <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
            <span>{{item.value}}</span>
          </p>
          </el-option>
        </el-select>
        <el-select
          clearable
          filterable
          placeholder="请输入后/直接选择组"
          v-model="pageInfoData.adGroup"
          class="el-select-select"
          @change="find"
         >
          <el-option
            v-for="item in adCollectionList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
           >
           <p>
             <span>({{item.type}})</span>-
             <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
             <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
             <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
             <span>{{item.value}}</span>
           </p>
          </el-option>
        </el-select>
        <el-select
          placeholder="关键词/自动投放产生"
          v-model="pageInfoData.keyWords"
          @change="handle_change"
          class="el-select-select"

          v-cloak
         >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          clearable
          placeholder="所有匹配类型"
          v-model="pageInfoData.matchType"
          class="el-select-select"
          @change="find"
         >
          <el-option
            v-for="item in AllMatchTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input style="width:350px;" class="el-input_input" v-model="queryWords" placeholder="请输入词" @keyup.enter.native = "find">
          <el-select 
            clearable
            @clear="clearAsin"
            slot="prepend"
            v-model="select_searchWords" 
            placeholder="选择输入类型" 
            style="width: 150px"
            >
             <el-option
               v-for="item in userSelectionSet"
               :key="item.id"
               :label="item.value"
               :value="item.id">
             </el-option>
          </el-select>
        </el-input>
         <el-button type="primary" icon="el-icon-search" @click="find">立即查询</el-button>
         <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleKeyword">添加到关键词</el-button>
         <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleNoKeyword">添加到否定关键词</el-button>
         <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleGoodsLocat">添加到商品定位</el-button>
         <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleNoGoodsLocat">添加到否定商品定位</el-button>
         <!-- 列表区域 -->
         <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          @sort-change="sortTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
         >
           <el-table-column type="selection" width="55" align="center"></el-table-column>
           <el-table-column label="用户搜索词" prop="name" align="center" :show-overflow-tooltip="true" width="160"></el-table-column>
           <el-table-column align="center">
             <template slot="header">
               <span>存在</span>
               <el-tooltip effect="dark" placement="top"> 
                 <div slot="content" style="line-height:20px;width:300px">
                   <p>用户搜索词是否存在于广告活动或者广告组中。</p>
                   <p>可能不存在, 也可能已经存在于否定商品定位, 否定关键词, 关键词投放和商品定位中。</p><br/>
                   <p>如果已经存在, 可以点击查看详情, 理解搜索词的使用具体情况。</p>
                 </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
               <el-link v-if="scope.row.count == 0" disabled>不存在</el-link>
               <el-tooltip effect="dark" content="点击查看详情" placement="top">
                 <el-link v-if="scope.row.count > 0" style="color: #008000" @click="judgeExist(scope.row)">存在({{scope.row.count}})</el-link>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column v-if="type == 1" label="关键词" prop="keywordText" align="center" :show-overflow-tooltip="true"></el-table-column>
           <el-table-column v-if="type == 2" label="投放" prop="targetText" align="center"></el-table-column>
           <el-table-column v-if="type == 1" label="匹配类型" align="center">
             <template slot-scope="scope">
               <span v-if="scope.row.keywordType == 'phrase'">手动-短语匹配</span>
               <span v-if="scope.row.keywordType == 'broad'">手动-宽泛匹配</span>
               <span v-if="scope.row.keywordType == 'exact'">手动-精确匹配</span>
             </template>
           </el-table-column>
           <el-table-column v-if="type == 2" label="投放类型" align="center" :show-overflow-tooltip="true">
             <template slot-scope="scope">
               <span v-if="scope.row.targetType == 'queryHighRelMatches'">自动-紧密匹配</span>
               <span v-if="scope.row.targetType == 'queryBroadRelMatches'">自动-宽泛匹配</span>
               <span v-if="scope.row.targetType == 'asinAccessoryRelated'">自动-关联商品</span>
               <span v-if="scope.row.targetType == 'asinSubstituteRelated'">自动-同类商品</span>
               <span v-if="scope.row.targetType == 'asinSameAs'">手动-asin投放</span>
               <span v-if="scope.row.targetType == 'asinCategorySameAs'">手动-category投放</span>
             </template>
           </el-table-column>
           <el-table-column label="分析" align="center">
             <template slot-scope="scope">
               <el-tooltip effect="dark" content="查看历史趋势" placement="top">
                 <el-link class="iconfont icon-jingyingfenxi" style="font-size:22px" @click="getChart(scope.row)"></el-link>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column label="广告组" prop="adGroupName" align="center" :show-overflow-tooltip="true"></el-table-column>
           <el-table-column label="广告活动" prop="campaignName" align="center" :show-overflow-tooltip="true"></el-table-column>
           <el-table-column prop="impressions" align="center" sortable='custom'>
             <template slot="header">
               <span>曝光量</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content">
                  <p>广告投放给买家的展示总数。</p><br>
                  <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="clicks" align="center" sortable='clicks'>
             <template slot="header">
               <span>点击量</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>点击量：广告被点击的总次数。</p>
                    <p>点击率：每一次曝光被点击的概率。</p><br>
                    <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                    <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                    <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                    <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="clickRate" align="center" sortable='clickRate'>
             <template slot="header">
               <span>点击率</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>点击量：广告被点击的总次数。</p>
                    <p>点击率：每一次曝光被点击的概率。</p><br>
                    <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                    <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                    <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                    <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="cost" align="center" sortable='cost'>
             <template slot="header">
               <span>花费</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                    <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
               {{currency}}{{scope.row.cost}}
             </template>
           </el-table-column>
           <el-table-column prop="clickAveCost" align="center" sortable='clickAveCost'>
             <template slot="header">
               <span>CPC均价</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>CPC点击的平均价格。</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
               {{currency}}{{scope.row.clickAveCost}}
             </template>
           </el-table-column>
           <el-table-column prop="attributedConversions14d" align="center" sortable='attributedConversions14d'>
             <template slot="header">
               <span>订单数</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>通过广告带来的订单量。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                  </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="cvr" align="center" sortable='cvr'>
             <template slot="header">
               <span>CVR</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>每一次点击带来订单的概率。</p><br>
                    <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                    <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                    <p>亚马逊广告，CVR一般为5-10%左右，</p>
                    <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="cpa" align="center" sortable='cpa'>
             <template slot="header">
              <span>CPA</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>每一笔广告订单平均所需花费用</p><br>
                    <p>计算规则：CPA = 花费 / 订单数；</p><br>
                    <p>商品的新品期，CPA相对较高，</p>
                    <p>商品的稳定期，CPA逐渐降低。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
             </template>
             <template slot-scope="scope">
               {{currency}}{{scope.row.cpa}}
             </template>
           </el-table-column>
           <el-table-column prop="attributedSales14d" align="center" sortable='attributedSales14d'>
             <template slot="header"> 
               <span>销售额</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>通过广告带来的销售额。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
             <template slot-scope="scope">
               {{currency}}{{scope.row.attributedSales14d}}
             </template>
           </el-table-column>
           <el-table-column prop="acos" align="center" sortable='acos'>
             <template slot="header">
               <span>ACoS</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                    <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                    <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                    <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                    <p>25%是业内普遍的衡量标准，</p>
                    <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                    <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                    <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                    <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                    <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                    <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
             </template>
           </el-table-column>
           <el-table-column prop="roas" align="center" sortable='roas'>
             <template slot="header">
               <span>ROAS</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>广告投入回报比，广告销售额与成本的倍数。</p><br>
                    <p>计算规则：RoAS = 销售额 / 花费；</p>
                    <p>例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。</p><br>
                    <p>RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，</p>
                </div>
               </el-tooltip>
             </template>
           </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           layout="total, sizes, prev, pager, next, jumper"
           :page-sizes="[20, 30, 50, 100]"
           :total="total"
           :page-size="20"
           :current-page="pageInfoData.current">
         </el-pagination>

         <!-- 添加搜索词到关键词 -->
         <el-dialog
          width="50%"
          title="添加搜索词到关键词"
          :visible.sync="keywordDialogVisible"
         >
          <el-table
           border
           ref="dataTable"
           style="width: 100%"
           :data="keywordTableData"
           v-loading="pictLoading"
           element-loading-text="数据正在加载中"
           element-loading-spinner="el-icon-loading"
           @selection-change="handleSelectionToggle"
           header-cell-style='background:#FAFAFA;text-align:center;font-size:14px'
          > 
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="用户搜索词" prop="userSearch" align="center" width="240px"></el-table-column>
            <el-table-column label="添加到" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.name" placeholder="请选择" style="width: 150px">
                  <el-option label="广告组" :value="scope.row.adGroupId"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="匹配类型" prop="matchType" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.matchType == 'exact'">精准</span>
                <span v-if="scope.row.matchType == 'phrase'">词组</span>
                <span v-if="scope.row.matchType == 'broad'">广泛</span>
              </template>
            </el-table-column>
            <el-table-column label="广告活动" align="center">
              <template slot-scope="scope">
                <el-select 
                 clearable
                 filterable
                 style="width: 150px" 
                 v-model="scope.row.adActivity" 
                 placeholder="请选择广告活动"
                 @clear="reset_Event(scope.$index)"
                >
                  <el-option
                   v-for="item in campaignsList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   @click.native="Toggle_Event(scope.row, item.id, scope.$index)"
                   :disabled="item.type == '自'? true : false"
                  >
                   <p>
                     <span>({{item.type}})</span>-
                     <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                     <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                     <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                     <span>{{item.value}}</span>
                   </p>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="广告组" align="center">
              <template slot-scope="scope">
                <el-select 
                  clearable
                  filterable
                  style="width: 150px" 
                  v-model="scope.row.adGroup" 
                  placeholder="请选择广告组"
                >
                  <el-option
                    v-for="item in scope.row.arr"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    :disabled="item.type=='商'||item.type=='自'? true : false"
                  >
                   <p>
                     <span>({{item.type}})</span>-
                     <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                     <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                     <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                     <span>{{item.value}}</span>
                   </p>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="建议竞价" align="center">
              <template slot-scope="scope">
                <el-link 
                 style="color: #2E8B57" 
                 @click="getSuggestedBids(scope.row, scope.$index)"
                 v-if="scope.row.suggested == undefinded"
                >获取</el-link>
                <div v-else-if="scope.row.suggested!=undefinded" style="font-size:14px; text-align:center">
                  <p>{{currency}}{{ scope.row.suggested }}</p>
                  <p>{{currency}}{{ scope.row.rangeStart }}--{{currency}}{{ scope.row.rangeEnd }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="默认竞价" align="center">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.defaultBiding" style="width: 140px">
                  <i slot="prefix">
                    <img src="../../../src/assets/common/rmb.png" alt="" style="width: 18px; height: 18px; margin-top: 8px">
                  </i>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer">
            <el-button @click="keywordDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addKeywordsToSearch">确 定</el-button>
          </span>
         </el-dialog>

         <!-- 添加搜索从到否定关键词 -->
         <el-dialog
          width="50%"
          title="添加搜索词到否定关键词"
          :visible.sync="noKeywordDialogVisible"
         >
           <el-table
            border
            style="width: 100%"
            :data="nokeywordTableData"
            ref="multipleTableClinicRoom"
            header-cell-style='background:#FAFAFA;text-align:center;font-size:14px'
           >
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="用户搜索词" prop="useSearch" align="center"></el-table-column>
            <el-table-column label="添加到" align="center">
              <template slot-scope="scope">
                <el-select 
                  placeholder="请选择" 
                  style="width: 150px" 
                  v-model="scope.row.keywordsType"
                >
                  <el-option
                   v-for="item in addCollectionList"
                   :key="item.keywordsType"
                   :label="item.lable"
                   :value="item.keywordsType"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="匹配类型" align="center">
              <template slot-scope="scope">
                <el-checkbox label="negativeExact" v-model="scope.row.type">否定精准</el-checkbox>
                <el-checkbox label="negativePhrase" v-model="scope.row.type">否定词组</el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="广告活动" align="center" width="240">
              <template slot-scope="scope">
                 <el-select
                  filterable
                  clearable
                  style="width: 180px" 
                  v-model="scope.row.adActivity" 
                  placeholder="请选择广告活动"
                  @clear="fn2(scope.$index)"
                 >
                   <el-option
                     v-for="item in campaignsList"
                     :key="item.id"
                     :label="item.value"
                     :value="item.id"
                     @click.native="func(scope.row, item.id, scope.$index)"
                     :disabled="item.type == '自'? true : false"
                   >
                   <p>
                     <span>({{ item.type }})</span>-
                     <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                     <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                     <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                     <span>{{ item.value }}</span>
                   </p>
                   </el-option>
                 </el-select>
              </template>
            </el-table-column>
            <el-table-column label="广告组" align="center" width="240">
              <template slot-scope="scope">
                <el-select filterable style="width: 180px" v-model="scope.row.adGroup2" placeholder="请选择广告组">
                  <el-option
                   v-for="item in scope.row.arr2"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   :disabled="item.type == '商'? true : false"
                  >
                  <p>
                    <span>({{item.type}})</span>-
                    <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                    <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                    <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                    <span>{{item.value}}</span>
                  </p>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
           </el-table>
           <span slot="footer">
             <el-button @click="noKeywordDialogVisible = false">取消</el-button>
             <el-button type="primary" @click="addNoKeywordsToSearch">确定</el-button>
           </span>
         </el-dialog>

         <!-- 温馨提示1 -->
         <el-dialog
          width="20%"
          title="温馨提示"
          :visible.sync="promptVisible"
         >
          <div class="styleClass">
           <el-row style="margin-bottom: 5px">产生方式为 关键词 只能添加到 关键词和否定关键词中</el-row><br/>
           <el-row>产生方式为asin 只能添加到商品定位和否定商品定位中</el-row><br/>
           <el-row style="margin-bottom: 5px">当前选择的&nbsp<span style="color: #FF0000">{{ multipleSelection.length }}</span>&nbsp项, 
           只有&nbsp<span style="color: #FF0000">{{ qualifiedNumber.length }}</span>&nbsp项, 符合当前添加条件, </el-row><br/>
           <el-row>系统会为您过滤掉不符合的选项, 是否继续?</el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="promptVisible = false">取消</el-button>
            <el-button type="primary" @click="asyncNext">继续</el-button>
          </span>
         </el-dialog>

         <!-- asin 添加到商品定位 -->
         <el-dialog
          width="60%"
          title="请选择用户搜索"
          :visible.sync="selectUserVisible"
         >
          <el-table
            border
            ref="multipleTableData"
            style="width: 100%"
            :data="tableDataSource"
            v-loading="tableLoading"
            element-loading-text="数据正在加载中"
            element-loading-spinner="el-icon-loading"
            header-cell-style='background:#FAFAFA;text-align:center;font-size:14px'
          > 
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="用户搜索词" prop="useSearch" align="center"></el-table-column>
            <el-table-column label="添加到" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.name" placeholder="请选择" style="width:100%">
                  <el-option label="广告组" :value="scope.row.adGroupId"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="商品定位" align="center">
              <template slot-scope="scope">
                <span style="font-size: 14px">ASIN</span>
              </template>
            </el-table-column>
            <el-table-column label="广告活动" align="center">
              <template slot-scope="scope">
               <el-select 
                clearable
                filterable
                style="width:100%" 
                v-model="scope.row.adActivity" 
                placeholder="请选择广告活动"
               >
                <el-option
                  v-for="item in campaignsList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                  :disabled="item.type == '自'? true : false"
                  @click.native="handle_btn(scope.row, item.id, scope.$index)"
                >
                <p>
                  <span>({{item.type}})</span>-
                  <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                  <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                  <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                  <span>{{item.value}}</span>
                </p>
                </el-option>
               </el-select>
              </template>
            </el-table-column>
            <el-table-column label="广告组" align="center">
              <template slot-scope="scope">
                <el-select 
                 clearable
                 filterable
                 style="width:100%"
                 v-model="scope.row.adGroup" 
                 placeholder="请选择广告组"
                >
                  <el-option
                   v-for="item in arrGroup"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   :disabled="item.type=='商'?false:true"
                  >
                  <p>
                    <span>({{item.type}})</span>-
                    <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                    <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                    <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                    <span>{{item.value}}</span>
                  </p>
                 </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="建议竞价" align="center">
              <template slot-scope="scope">
                <el-link type="primary" @click="toGetBidding(scope.row)">获取</el-link>
              </template>
            </el-table-column>
            <el-table-column label="默认竞价" align="center">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.defaultFor" style="width:100%"></el-input>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="selectUserVisible = false">取消</el-button>
            <el-button type="primary" @click="asyncConfirm1">确定</el-button>
          </span>
         </el-dialog>

         <!-- 温馨提示2 -->
         <el-dialog
          width="20%"
          title="温馨提示"
          :visible.sync="promptVisible2"
         >
          <div class="styleClass">
            <el-row style="margin-bototm: 5px">产生方式为 关键词 只能添加到 关键词和否定关键词中</el-row><br/>
            <el-row>产生方式为asin 只能添加到商品定位和否定商品定位中</el-row>
            <el-row style="amrgin-bottom: 5px">当前选择的&nbsp<span style="color: #FF0000">{{ multipleSelection.length }}</span>&nbsp项,
             只有&nbsp<span style="color: #FF0000">{{qualifiedNumber.length}}</span>&nbsp项, 符合当前添加条件, </el-row><br/>
            <el-row>系统会为您过滤掉不符合的选项, 是否继续?</el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="promptVisible2 = false">取消</el-button>
            <el-button type="primary" @click="asyncContinue">继续</el-button>
          </span>
         </el-dialog>

         <!-- 温馨提示3 -->
         <el-dialog
          width="20%"
          title="温馨提示"
          :visible.sync="promptVisible3"
         >
          <div class="styleClass">
            <el-row style="margin-bototm: 5px">产生方式为 关键词 只能添加到 关键词和否定关键词中</el-row><br/>
            <el-row>产生方式为asin 只能添加到商品定位和否定商品定位中</el-row><br/>
            <el-row style="amrgin-bottom: 5px">当前选择的&nbsp<span style="color: #FF0000">{{ multipleSelection.length }}</span>&nbsp项,
              只有&nbsp<span style="color: #FF0000">{{qualifiedNumber.length}}</span>&nbsp项, 符合当前添加条件, </el-row><br/>
            <el-row>系统会为您过滤掉不符合的选项, 是否继续?</el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="promptVisible3 = false">取消</el-button>
            <el-button type="primary" @click="asyncNextStep">继续</el-button>
          </span>
         </el-dialog>

         <!-- 温馨提示4 -->
         <el-dialog
           width="20%"
           title="温馨提示"
           :visible.sync="promptVisible4"
         >
          <div class="styleClass">
            <el-row style="margin-bototm: 5px">产生方式为 关键词 只能添加到 关键词和否定关键词中</el-row><br/>
            <el-row>产生方式为asin 只能添加到商品定位和否定商品定位中</el-row><br/>
            <el-row style="amrgin-bottom: 5px">当前选择的&nbsp<span style="color: #FF0000">{{ multipleSelection.length }}</span>&nbsp项,
              只有&nbsp<span style="color: #FF0000">{{qualifiedNumber.length}}</span>&nbsp项, 符合当前添加条件, </el-row>
            <br/>
            <el-row>系统会为您过滤掉不符合的选项, 是否继续?</el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="promptVisible4 = false">取消</el-button>
            <el-button type="primary" @click="continueStep">继续</el-button>
          </span>
         </el-dialog>

         <!-- asin到否定商品定位 -->
         <el-dialog
          width="50%"
          title="添加搜索词到否定商品定位"
          :visible.sync="negateGoodsVisible"
         >
          <el-table
            border
            ref="tableDataRef"
            :data="negateGoodsList"
            header-cell-style='background:#FAFAFA;text-align:center;font-size:14px'
          >
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="用户搜索词" prop="useSearch" align="center"></el-table-column>
            <el-table-column label="添加到" align="center">
              <template slot-scope="scope">
                <el-select
                  placeholder="请选择"
                  style="width: 150px"
                  v-model="scope.row.keywordsType"
                >
                  <el-option
                   v-for="item in addCollectionList"
                   :key="item.keywordsType"
                   :label="item.lable"
                   :value="item.keywordsType"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="商品定位" align="center">
              <template slot-scope="scope">
                <span style="font-size: 14px">排除ASIN</span>
              </template>
            </el-table-column>
            <el-table-column label="广告活动" align="center">
              <template slot-scope="scope">
                <el-select 
                  filterable
                  clearable
                  style="width: 150px" 
                  v-model="scope.row.adActivity" 
                  placeholder="请选择广告活动"
                >
                  <el-option
                   v-for="item in campaignsList"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                   :disabled="item.type == '自' ? true : false"
                  >
                  <p>
                    <span>({{item.type}})</span>-
                    <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                    <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                    <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                    <span>{{item.value}}</span>
                  </p>
                 </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="广告组" align="center">
              <template slot-scope="scope">
                <el-select 
                  style="width: 150px" 
                  v-model="scope.row.adGroup" 
                  placeholder="请选择广告组"
                >
                  <el-option
                    v-for="item in groupList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    :disabled="item.type == '商'? true : false"
                  >
                  <p>
                    <span>({{item.type}})</span>
                    <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                    <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                    <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                    <span>{{item.value}}</span>
                  </p>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button @click="negateGoodsVisible = false">取消</el-button>
            <el-button type="primary"  @click="asyncConfirm2">确定</el-button>
          </span>
         </el-dialog>

        <!-- 搜索词使用详情 -->
        <el-dialog
         width="50%"
         :visible.sync="useDetailsVisible"
        >
          <span slot="title">
            <span class="header-main-title">搜索词({{UseSearchName}})系统使用详情</span>
          </span>
          <el-table :data="total_data"  height="400">
            <el-table-column label="所属对象" prop="belongToName" align="center"></el-table-column>
            <el-table-column label="对象名称" prop="groupName" align="center"></el-table-column>
            <el-table-column label="活动名称" prop="campaignName" align="center"></el-table-column>
            <el-table-column label="使用方式" prop="typeName" align="center"></el-table-column>
            <el-table-column label="使用状态" prop="state" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state == '已启用'" style="color: #3CB371">{{scope.row.state}}</span>
                <span v-if="scope.row.state == '已暂停'" style="color: #B22222">{{scope.row.state}}</span>
                <span v-if="scope.row.state == '已归档'" style="color: #808080">{{scope.row.state}}</span>
              </template>
            </el-table-column>
            <el-table-column label="匹配类型/投放方式" prop="matchType" align="center"></el-table-column>
            <el-table-column label="建议竞价" align="center">
              <template slot-scope="scope">
                <el-link 
                 style="color: green" 
                 @click="toObtainEvent(scope.$index, scope.row)"
                 v-if="scope.row.suggested == undefinded && scope.row.state != 'archived'"
                >获取</el-link>
                <div v-else-if="scope.row.suggested!=undefinded" style="font-size:14px;text-align:left">
                  <p>¥{{ scope.row.suggested }}</p>
                  <p>¥{{ scope.row.rangeStart }}--¥ {{ scope.row.rangeEnd }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="竞价" align="center">
              <template slot-scope="scope">
                <el-link @click="adjustBid(scope.row, scope.$index)" style="color: green" :disabled="scope.row.state == '已归档'? true: false">¥{{scope.row.bid}}</el-link>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="useDetailsVisible = false">关 闭</el-button>
          </span>
        </el-dialog>

        <!-- 底部抽屉区域 -->
        <el-drawer
          title="广告表现"
          :modal="false"
          size="70%"
          :visible.sync="drawer"
          :direction="direction"
          :custom-class="DrawerClass"
          :before-close="handleClose"
        >
          <TendencyChart 
          :list.sync='ListOfData' 
          :info='`搜索词：${searchTermName}`'
          :legendList='legendList'
          :currency="currency"
          @analysisClick='analysisClick'></TendencyChart>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum'
import TendencyChart from './TendencyChart.vue'
import {
  getPageInit,  // 初始化
  getListPage,  // 查询
  increaseKeywords,  // 新增关键词-否定关键词
  addTargets,  // 新增商品定位-否定产品订单
  getListKeywordExist,  // 获取搜索词(keyword)使用情况
  getListTargetExist,   // 获取搜索词(target)使用情况
  modifyBid,
  getKeywordBidRecommendations,
  listByQuery,  // 表现明细查询
  getKeywordBidRecommendationsByText,  // 根据关键词Text获取建议竞价
}from '@/api/Advertising/searchword.js'
export default {
  components: {
    TimeQuantum,
    TendencyChart
  },
  data() {
    return {
      ListOfData: [],
      drawer: false,
      direction: 'btt',
      loading: false,
      promptVisible: false,
      promptVisible2: false,
      promptVisible3: false,
      promptVisible4: false,
      selectUserVisible: false,
      negateGoodsVisible: false,
      useDetailsVisible: false,
      total: 0,
      pictLoading: false,
      typeList: [],
      portfoliosList: [],
      groupList: [],
      shopLists: [],
      campaignsList: [],
      groupStateList: [],
      keywordMatchTypeList: [],
      targetMatchTypeList: [],
      tableData: [],
      AllMatchTypeList: [],
      tableDataSource: [],
      negateGoodsList: [],
      pageInfoData: {
        profileId: "",  //  店铺名称
        adCombination: "",
        adActivity: "",
        adGroup: "",
        keyWords: "keywords",  // 关键词
        matchType: "",   // 匹配类型
        searchType: "",  // 查询方式
        isExist: "",  //  是否存在
        current: 1,   // 当前页
        pageSize: 20,  // 每页显示条数
        startTime: "",  // 开始事件
        endTime: "",   //  结束时间
        sort:'',//排序
        sortColumn:'',
        shopId: "",
        marketplaceId: "",
      },
      queryWords: "",  
      select_searchWords: "searchword",  
      multipleSelection: [],
      qualifiedNumber: [],  // 符合条件的数据
      keywordDialogVisible: false,
      matchTypeList: [ 
        { id: "exact", value: "精准" },
        { id: "phrase", value: "词组" }, 
        { id: "broad", value: "广泛" }
      ],
      userSelectionSet: [
        { id: "searchword", value: "用户搜索词" },
        { id: "keyword", value: "关键词" }
      ],
      keywordTableData: [],
      adGroupId: "",
      nokeywordTableData: [],
      noKeywordDialogVisible: false,
      addCollectionList: [
        { lable: '广告组', value: 'adGroupId', keywordsType: 2},
        { lable: '广告活动', value: 'campaignId', keywordsType: 3 }
      ],
      keywordsType: "",
      type: 1,    // type: 1 (关键词/匹配类型)  type: 2 (投放/投放类型)
      adVertisCampaignList: [],
      adCollectionList: [],
      list: [],
      typeDatas: [],
      UseSearchName: "",
      total_data: [],
      adjustaBidId: "",
      searchTermName: "",
      chartInatance: null,   //  保存chartsInstance 数据
      legendList: [
        {name:"曝光量",prop:'impressions'},
        {name:"点击量",prop:'clicks'},
        {name:"点击率",prop:'clickRate',persent:true},
        {name:"花费",prop:'cost',currency:true},
        {name:"CPC均价",prop:'clickAveCost',currency:true},
        {name:"订单数",prop:'attributedConversions14d'},
        {name:"CVR",prop:'cvr',persent:true},
        {name:"CPA",prop:'cpa',currency:true},
        {name:"销售额",prop:'attributedSales14d'},
        {name:"ACoS",prop:'acos',persent:true},
        {name:"RoAS",prop:'roas',persent:true},
      ],
      currency:'',
      multipleSelectedEvent: [],
      tableLoading: false,
      arrGroup: [],
    }
  },
  created() {
    this.getSearchInit();
    this.$nextTick(()=> {
      setTimeout(()=> {
        this.AllMatchTypeList = this.keywordMatchTypeList;
      }, 500)
    });
    setTimeout(()=> {
      this.judgeCombina();
      this.judgeActivity();
    }, 1000)
  },
  watch: {
    $route(to) {
      let initInfo = this.$route.query;
      if(JSON.stringify(initInfo)!='{}') {
        if(initInfo.type == 'getList') {
          this.groupList.profileId = Number(initInfo.profileId);
          this.groupList.forEach(item=> {
            // 广告活动初始化
            this.pageInfoData.adActivity = item.campaignId;
            if(item.id == initInfo.id) {
              this.pageInfoData.adGroup = item.id;
            }
          })
          this.handleSearch();
        }
      }else {
        this.handleSearch();
      }
    }
  },
  mounted() {},
  methods: {
    handleClose(){
      this.ListOfData=[]
      this.drawer=false
    },
    getSearchInit(val) {
      getPageInit(this.pageInfoData.profileId||this.$store.state.profileId?this.pageInfoData.profileId||this.$store.state.profileId:0) //
       .then(res=> {
         let result = res.data.data;
         if(res.data.code == 200) {
           this.shopLists = result.shopList;   // 店铺
           this.campaignsList = result.campaignsList;   // 输入后直接选择广告活动
           this.groupList = result.groupList;  // 输入后直接选择组
           this.groupStateList = result.groupStateList;   // 
           this.keywordMatchTypeList = result.keywordMatchTypeList;  // 
           this.targetMatchTypeList = result.targetMatchTypeList;  // 所有匹配类型
           this.portfoliosList = result.portfoliosList;    // 输入后直接选择组合
           this.typeList = result.typeList;    // 所有匹配类型
           if(this.pageInfoData.profileId == "") {
             this.shopLists.forEach(item=> {
               item.marketPlaceTree.forEach(data=> {
                 if(data.isSelected == 1) {
                   this.pageInfoData.profileId = data.profileId;
                   this.currency=data.currency
                 }
               })
             })
           }
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
         if(val == "currentPage") {
           this.handleSearch();
           return false;
         }
       })
       .then(()=> {
         this.judgeActivity();
         let searchInfo = this.$route.query;
         if(JSON.stringify(searchInfo)!='{}') {
           if(searchInfo.type == 'getList') {
             this.groupList.profileId = Number(searchInfo.profileId);
             this.pageInfoData.adActivity = Number(searchInfo.campaignId);
             this.pageInfoData.adGroup = Number(searchInfo.id);
             this.handleSearch();
           }
         }else {
           this.handleSearch();
         }
       })
    },
    topTime(val) {
      this.pageInfoData.startTime = val.value[0];
      this.pageInfoData.endTime = val.value[1];
    },
    // 查询事件
    handleSearch() {
      this.$store.commit('setProfileId', this.pageInfoData.profileId);
      this.loading = true;
      let select_keyWord = "";
      if(this.select_searchWords == "" || this.queryWords == "") {
        select_keyWord = null;
      }else 
      if(this.select_searchWords == "searchword") {
        select_keyWord = { searchword: this.queryWords }
      }else if(this.select_searchWords == "keyword") {
        select_keyWord = { keyword: this.queryWords }
      }
      let params = {
        shopId: this.pageInfoData.shopId,   // 店铺id
        marketplaceId: this.pageInfoData.marketplaceId,   // 站点id
        startDate: this.pageInfoData.startTime,   // 开始时间
        endDate: this.pageInfoData.endTime,  //  结束时间
        current: this.pageInfoData.current,  // 当前页
        pageSize: this.pageInfoData.pageSize,   // 每页条数
        keywordType: this.pageInfoData.matchType,  // 关键词/商品匹配类型
        type: this.pageInfoData.keyWords,            //  关键词keywords/产品targets类型
        profileId: this.pageInfoData.profileId,  //  组合id
        portfolioId: this.pageInfoData.adCombination,
        campaignId: this.pageInfoData.adActivity,  //  活动id
        groupsId: this.pageInfoData.adGroup,   // 广告组id
        sort: this.pageInfoData.sort, 
        sortColumn: this.pageInfoData.sortColumn
      }
      getListPage({...select_keyWord, ...params})
      .then(res=> {
        this.loading = false;
        this.tableData = [];
        if(res.data.code == 500){
          this.$message.error(res.data.message);
          return false; 
        }
        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
        this.loading = false;
      })
    },
    handle_change(val) {
      console.log(val, '12345612345');
      this.AllMatchTypeList = [];
      if(val == 'keywords') {
        this.type = 1;
        this.AllMatchTypeList = this.keywordMatchTypeList;
        this.handleSearch();
      }else if(val == 'targets') {
        this.type = 2;
        this.AllMatchTypeList = this.targetMatchTypeList;
        this.handleSearch();
      }
    },
    // 判断当前选中行是否有asin 的方法
    judgeExistAsin() {
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const list = [];
      this.multipleSelection.forEach(item=> {
        // 判断选中的每一项的name 值是否是以b + 两位数字开头
        if(reg.test(item.name)) { 
          const reg1 = reg.test(item.name);  // 如果为true, 包含b +两位数字开头
          list.push(reg1);
          if(list.length !=0 ) {     // 长度不为0, 说明包含以b 字开头
            // (不包含b字开头的关键词)
            this.qualifiedNumber.length = this.multipleSelection.length - list.length;
            this.promptVisible = true;
            this.keywordDialogVisible = false;
            return false;
          }
        }else {
          const arrList = [];
          this.multipleSelection.forEach(item=> {
            this.matchTypeList.forEach((data, index)=> {
              arrList.push({
                userSearch: item.name,  // 用户搜索词
                matchType: data.id,   // 匹配类型
                adGroupId: item.adGroupId,
                adGroup: '',
                name: '广告组',
                arr: [],
                adActivity: item.campaignId
              })
            })
            this.keywordTableData = arrList;
            this.$nextTick(()=> {
              this.keywordTableData.forEach(row=> {
                this.$refs.dataTable.toggleRowSelection(row, true);
              })
            })
          })
          this.keywordDialogVisible = true;   // 长度为0, 说明不包含以b 开头
        }
      })
    },
    // 判断当前选中行是否有asin 方法
    judgeExistAsin2() {
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const collection = [];
      this.multipleSelection.forEach(item=> {
        // 判断选中的每一项的name 字段是否是以b + 数字开头 
        if(reg.test(item.name)) {
          const reg1 = reg.test(item.name);   // 如果为true, 包含b+ 开头的两两位数字
          collection.push(reg1);
          if(collection.length !=0) {   //  长度不为0, 说明包含以b 字开头
            // (不包含b字开头的关键词)
            this.qualifiedNumber.length = this.multipleSelection.length - collection.length;
            this.promptVisible2 = true;
            this.noKeywordDialogVisible = false;
            return false;
          }
        }else {
          const arrayList = [];
          this.multipleSelection.forEach(item=> {
            arrayList.push({
              useSearch: item.name,
              adGroupId: item.adGroupId,
              adGroup2: "",
              name: '广告组',
              type: [],  // 匹配类型
              arr2: [],
              adActivity: item.campaignId,
              keywordsType: "",
            })
          })
          this.nokeywordTableData = arrayList;
          this.$nextTick(()=> {
            this.nokeywordTableData.forEach(data=> {
              this.$refs.multipleTableClinicRoom.toggleRowSelection(data, true);
            })
          })
          this.noKeywordDialogVisible = true;   // 长度为0, 说明不包含以b 开头
        }
      })
    },
    // 判断当前选中行是否有asin 方法
    judgeExistAsin3() {
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const dataZoom = [];
      this.multipleSelection.forEach(item=> {
        // 判断选中的每一项的name 值是否是以b + 两位数字开头
        if(reg.test(item.name)) {     // 包含b + 两位数字开头
          const reg1 = reg.test(item.name);
          dataZoom.push(reg1);
          dataZoom.forEach(it2=> {
            if(it2 == true) {
              this.promptVisible3 = false;
              this.selectUserVisible = true;
            }
          })
          if(dataZoom.length != 0) {    // 长度不为0, 说明包含以b 字开头
            // (不包含b字开头的关键词)
            this.qualifiedNumber.length = dataZoom.length;  // (不包含b 字开头的关键词)
            this.promptVisible3 = true;
            this.selectUserVisible = false;
            return false;
          }
        }else {
          const listData = [];
          this.multipleSelection.forEach(item=> {
            listData.push({
              useSearch: item.name,
              adGroupId: item.adGroupId,
              campaignId: item.campaignId,
              name: '广告组',
              adGroup: "",
            }) 
          })
          this.tableDataSource = listData;
          this.$nextTick(()=> {
            this.tableDataSource.forEach(row=> {
              this.$refs.multipleTableData.toggleRowSelection(row, true);
            })
          })
          this.selectUserVisible = true; 
        }
      })
    },
    // 判断当前选中行是否有asin 的方法
    judgeExistAsin4() {
      console.log(4);
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const colleList = [];
      this.multipleSelection.forEach(item=> {
        // 判断选中的每一项的name 值是否是以b + 两位数字开头
        if(reg.test(item.name)) {     // 包含b + 两位数字开头
          const reg1 = reg.test(item.name);
          colleList.push(reg1);
          if(colleList.length !=0 ) {   // 长度不为0, 说明包含以b 开头
            // (不包含b字开头的关键词)
            this.qualifiedNumber.length = colleList.length; 
            this.promptVisible4 = true;
            this.negateGoodsVisible = false;
            return false;
          }
        }else {
          const listList = [];
          this.multipleSelection.forEach(item=> {
            listList.push({
              userSearch: item.name,
              adGroupId: item.adGroupId,
              campaignId: item.campaignId,
              name: '广告组'
            })
          })
          this.negateGoodsList = listList;
          this.$nextTick(()=> {
            this.tableDataSource.forEach(data=> {
              this.$refs.multipleTableData.toggleRowSelection(data, true);
            })
          })
          this.negateGoodsVisible = false;
        }
      })
      
    },
    // 添加到关键词
    handleKeyword() {
      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择用户搜索词');
        return false;
      }
      this.judgeExistAsin();  // 判断当前选中项是否有asin 的方法 
    },
    // 添加到否定关键词
    handleNoKeyword() {
      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择用户搜索词');
        return false;
      }
      this.judgeExistAsin2();
    },
    // 添加商品定位
    handleGoodsLocat() {
      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择用户搜索词');
        return false;
      }
      this.judgeExistAsin3();
    },
    // 添加到否定商品定位
    handleNoGoodsLocat() {
      if(this.multipleSelection.length == 0) {
        this.$message.error('请选择用户搜索词');
        return false;
      }
      this.judgeExistAsin4();
    },
    handleSizeChange(newSize) {
      this.pageInfoData.pageSize = newSize;
      this.handleSearch();
    },  
    handleCurrentChange(newCurrent) {
      this.pageInfoData.current = newCurrent;
      this.handleSearch();
    },
    // table 多选款状态
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    checkGroup(parentId, subId, portfolioId,currency) {
      this.pageInfoData = {
        profileId: "",
        startTime: this.pageInfoData.startTime,   // 开始时间
        endTime: this.pageInfoData.endTime,  // 结束时间
        adCombination: "",
        adActivity: "",
        adGroup: "",
        keyWords: "keywords",
        matchType: "",
        profileId: "",
        current: "",
        pageSize: "20",
        sort: "",
        sortColumn: "",
      }
      // this.select_searchWords = "";
      this.pageInfoData.profileId = portfolioId;
      this.getSearchInit('currentPage');
      this.pageInfoData.shopId = parentId;
      this.pageInfoData.marketplaceId = subId;
      this.currency=currency
    },
    handleSelectionToggle(val) {
      this.multipleSelectedEvent = val;
    },
    // 添加搜索词到关键词
    addKeywordsToSearch() {
      if(this.multipleSelectedEvent.length == 0) {
        this.$message.error('请选择要添加搜索词');
        return false;
      }
      for (let m=0; m<this.keywordTableData.length; m++) {
        if(!this.keywordTableData[m].defaultBiding) {
          this.$message.error('默认竞价不能为空');
          return false;
        }
      }
      const arrayList = [];
      this.keywordTableData.forEach(item=> {
        let object = {
          adGroupId: item.adGroup,    // 广告组Id
          bid: item.defaultBiding,    // 默认竞价
          campaignId: item.adActivity,  // 广告活动的id
          keywordText: item.userSearch,  // 关键词
          keywordsType: 1,  
          matchType: item.matchType
        }
        arrayList.push(object);
      })
      increaseKeywords(arrayList, this.pageInfoData.profileId)
      .then(res=> {
        if(res.data.code == 200) {
          this.keywordDialogVisible = false;
          this.$message.success(res.data.message);
        }else if(res.data.code == 500) {
          this.$message.error(res.data.message);
        } 
      })
    },
    // 添加否定关键词到搜索
    addNoKeywordsToSearch() {
      for (let index=0; index<this.nokeywordTableData.length; index++) {
        if(!this.nokeywordTableData[index].keywordsType) {
          this.$message.error('添加到不能为空');
          return false;
        } 
      }
      for (let h=0; h<this.nokeywordTableData.length; h++) {
        if(!this.nokeywordTableData[h].adGroup2) {
          this.$message.error('广告组不能为空');
          return false;
        }
      }
      const arrayList = [];
      this.nokeywordTableData.forEach(item=> {
        let data1 = {
          adGroupId: item.adGroup2,
          campaignId: item.adActivity,
          keywordText: item.useSearch,
          keywordsType: item.keywordsType,
          matchType: item.type.join(','),
        }
        arrayList.push(data1);
      })
      increaseKeywords(arrayList, this.pageInfoData.profileId)
      .then(res=> {
        if(res.data.code == 200) {
          this.noKeywordDialogVisible = false;
          this.$message.success(res.data.message);
        }else if(res.data.code == 500) {
          this.noKeywordDialogVisible = false;
          this.$message.error(res.data.message);
        }
      })
    },
    changeTime(val) {
      this.pageInfoData.startTime = val.value[0];
      this.pageInfoData.endTime = val.value[1];
    },
    // 查看历史趋势
    getChart(row){
      this.searchTermName = row.name;
      this.analysisClick()
    },
    analysisClick(val) {
      let params = {
        profileId: this.pageInfoData.profileId,
        startDate: val?val.value[0]:'',
        endDate: val?val.value[1]:'',
        query:this.searchTermName
      }
      console.log(params);
      listByQuery(params)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          if(res.data.data.length==0){
            this.$message.warning('暂无数据')
          } 
          this.ListOfData = res.data.data;
        }
      })
      this.drawer = true;
    },
    // 添加到关键词继续
    asyncNext() {
      // 判断是否有asin 的方法
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const type = [];
      this.multipleSelection.forEach(item=> {
        if(!reg.test(item.name)) {     // 不包含b + 数字开头
          this.matchTypeList.forEach((data, index)=> {
            type.push({
              userSearch: item.name,  // 用户搜索词
              matchType: data.id,   // 匹配类型
              adGroupId: item.adGroupId,
              name: '广告组',
              adGroup: '',
              arr: [],
              adActivity: item.campaignId
            })
          })
        }
      })
      this.keywordTableData = type;
      this.$nextTick(()=> {
        this.keywordTableData.forEach(row=> {
          this.$refs.dataTable.toggleRowSelection(row, true);
        })
      })
      this.promptVisible = false;
      this.keywordDialogVisible = true;
    },
    // 添加到否定关键词继续
    asyncContinue() {
      // 对选中的数值进行赋值操作
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const typeList = [];
      this.multipleSelection.forEach(item=> {
        if(!reg.test(item.name)) {     // 不包含b + 数字开头
          typeList.push({
            useSearch: item.name,
            adGroupId: item.adGroupId,
            campaignId: item.campaignId,
            name: '广告组',
            type: [],  // 匹配类型
          })           
        }
      })
      this.nokeywordTableData = typeList;
      this.$nextTick(()=> {
        this.nokeywordTableData.forEach(data=> {
          this.$refs.multipleTableClinicRoom.toggleRowSelection(data, true);
        })
      })
      this.promptVisible2 = false;
      this.noKeywordDialogVisible = true;
    },
    // 添加商品定位继续
    asyncNextStep() {
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const typeList = [];
      this.multipleSelection.forEach(item=> {
        if(reg.test(item.name)) {
          typeList.push({
            useSearch: item.name,
            adGroupId: item.adGroupId,
            campaignId: item.campaignId,
            name: '广告组',
            adGroup: "",
            arrGroup: [],   //  广告组
          })
        }
      })
      this.tableDataSource = typeList;
      this.$nextTick(()=> {
        this.tableDataSource.forEach(row=> {
          this.$refs.multipleTableData.toggleRowSelection(row, true);
        })
      })
      this.promptVisible3 = false;
      this.selectUserVisible = true;
    },
    // 添加到商品定位
    asyncConfirm1() {
      const arrCollect = [];
      this.tableDataSource.forEach(item=> {
        let obj = {
          adGroupId: item.adGroup,
          bid: item.defaultFor,
          campaignId: item.adActivity,
          asin: item.useSearch,
          targetsType: 1
        }
        arrCollect.push(obj);
      })
      addTargets(arrCollect, this.pageInfoData.profileId)
       .then(res=> {
         if(res.data.code == 200) { 
           this.$message.success(res.data.message);
           this.selectUserVisible = false;
           this.handleSearch();
         }else if(res.data.code == 500) { 
           this.$message.error(res.data.message);
         }
       })
    },
    // 商品否定继续
    continueStep() {
      const reg = /^[a-zA-Z][0-9]{1}[0-9]{1}\w{7}$/
      const typeData = [];
      this.multipleSelection.forEach(item=> {
        if(reg.test(item.name)) {
          typeData.push({
            useSearch: item.name,
            adGroupId: item.adGroupId,
            campaignId: item.campaignId,
            name: '广告组',
          }) 
        }
      })
      this.negateGoodsList = typeData;
      this.$nextTick(()=> {
        this.negateGoodsList.forEach(row=> {
          this.$refs.tableDataRef.toggleRowSelection(row, true);
        })
      })
      this.promptVisible4 = false;
      this.negateGoodsVisible = true;
    },
    // 否定商品确认
    asyncConfirm2() {
      const arrList = [];
      this.negateGoodsList.forEach(item=> {
        arrList.push({
          adGroupId: item.adGroup,
          campaignId: item.adActivity,
          asin: item.useSearch,
          targetsType: item.keywordsType
        }) 
      })
      addTargets(arrList, this.pageInfoData.profileId)
       .then(res=> {
         if(res.data.code == 200) {
           this.$message.success(res.data.message);
           this.negateGoodsVisible = false;
           this.handleSearch();
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
           this.negateGoodsVisible = false;
         }
       })
    },
    // 根据组合选择广告活动
    onClickEvent(id) {
      this.campaignsList.forEach(item=> {
        if(item.portfolioId == id) {
          this.list.push(item)
        }
        this.adVertisCampaignList = this.list;
      })
      this.pageInfoData.adActivity = "";
    },
    // 通过广告活动获取组
    select_Event(id) {
      this.groupList.forEach(data=> {
        if(id == data.campaignId) {
          this.typeDatas.push(data);
        }
        this.adCollectionList = this.typeDatas;
      })
    },
    // 直接选择组合清空事件
    emptyEvent() {
      this.pageInfoData.adActivity = "";
      this.adVertisCampaignList = this.campaignsList;
    },
    // 广告活动清空事件
    remove_Event() {
      this.pageInfoData.adGroup = "";
      this.adCollectionList = this.groupList;
    },
    // 判断选择组合id 是否为空
    judgeCombina() {
      if(!this.pageInfoData.adCombination) {
        this.adVertisCampaignList = this.campaignsList;
      }else {
        this.adVertisCampaignList = this.list;
      }
    },
    // 判断活动id 是否存在
    judgeActivity() {
      if(!this.pageInfoData.adActivity) {
        this.adCollectionList = this.groupList;
      }else {
        this.adCollectionList = this.typeDatas;
      }
    },
    Toggle_Event(row, id, index) {
      let types = [];
      this.groupList.forEach(type=> {
        if(id == type.campaignId) {
          types.push(type);
        }
      })
      this.keywordTableData[index].adGroup = "";    
      this.keywordTableData[index].arr = types;
    },
    reset_Event(index) {
      this.keywordTableData[index].adGroup = "";
      this.keywordTableData[index].arr = this.groupList;
    },
    func(row, id, _index) {
      let type_c = [];
      this.groupList.forEach(it=> {
        if(id == it.campaignId) {
          type_c.push(it);
        }
      })
      this.nokeywordTableData[_index].adGroup2 = "";
      this.nokeywordTableData[_index].arr2 = type_c;
    },
    fn2(_ind) {
      this.nokeywordTableData[_ind].adGroup2 = "";
      this.nokeywordTableData[_ind].arr2 = this.groupList;
    },
    handle_btn(row, id, ind) {
      let map_c = [];
      this.groupList.forEach(item=> {
        if(id == item.campaignId) {
          map_c.push(item);
        }
      })
      this.arrGroup = map_c;
      this.tableDataSource[ind].adGroup = "";
      // this.tableDataSource[ind].arrGroup = map_c;
    },
    // 搜索词存在情况
    judgeExist(row) {
      this.UseSearchName = row.name;
      this.loading = true;
      if(this.type == 1) {
        let params = {
          keyword: row.name,
          profileId: this.pageInfoData.profileId 
        }
        getListKeywordExist(params)
         .then(res=> {
           this.loading = false;
           this.total_data = [];
           if(res.data.code == 200) {
             this.total_data = res.data.data;
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }
        })
      }else if(this.type == 2) {
        let params = {
          keyword: row.name,
          profileId: this.pageInfoData.profileId 
        }
        getListTargetExist(params)
         .then(res=> {
           this.loading = false;
           this.total_data = [];
           if(res.data.code == 200) {
             this.total_data = res.data.data;
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }
         })
      }
      this.useDetailsVisible = true;
    },

    adjustBid(row, index) {
      console.log(row)
      this.adjustaBidId = row.id;
      this.$prompt('竞价', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: row.bid,
        inputPattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
        inputErrorMessage: '只能输入纯数字'
      }).then(({value})=> {
        if(this.type == 1) {
          modifyBid({bid: parseInt(value), ids: [row.id], type: 'keywords'})
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.total_data[index].bid = value;
            }
          })
        }else if(this.type == 2) {
          modifyBid({bid: value, ids: [row.id], type: 'targets'})
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.total_data[index].bid = value;
            }
          })
        }
      })
    },
    // 获取建议竞价
    toObtainEvent(index, row) {
      this.loading = true;
      getKeywordBidRecommendations(row.id, this.pageInfoData.profileId)
       .then(res=> {
         this.loading = false;
         if(res.data.code == 200) {
           if(res.data.data == "") {
             this.$message.warning("暂无建议竞价")
           } 
           this.$set(this.total_data[index], 'suggested', res.data.data.suggested);
           this.$set(this.total_data[index], 'rangeEnd',res.data.data.rangeEnd);
           this.$set(this.total_data[index], 'rangeStart',res.data.data.rangeStart);
         }else if(res.data.code == 500){
           this.$message.error(res.data.message);
         }
       })
    },
    // 添到商品定位获取竞价(未完待续....)
    toGetBidding(row) {
      console.log(row);
      // this.tableLoading = true;
    },
    sortTable(column){
        console.log(column);
        if(column.order=='ascending'){
            this.pageInfoData.sort='asc'
        }else if(column.order=='descending'){
            this.pageInfoData.sort='desc'
        }else if(column.order==null){
            this.pageInfoData.sort=''
            this.pageInfoData.sortColumn=''
            this.handleSearch()
            return
        }
        this.pageInfoData.sortColumn=column.prop
        this.handleSearch()
    },
    find(){
      this.pageInfoData.sort=''
      this.pageInfoData.sortColumn=''
      this.pageInfoData.current=1
      this.handleSearch()
    },
    // 搜索词添加到关键词获取建议竞价
    getSuggestedBids(row, index) {
      this.pictLoading = true;
      let array = [
        {
          keyword: row.userSearch,
          matchType: row.matchType
        }
      ];
      let data = {
        adGroupId: row.adGroupId,
        keywords: array
      }
      getKeywordBidRecommendationsByText(data, this.pageInfoData.profileId)
      .then(res=> {
        this.pictLoading = false;
        if(res.data.code == 200) {
          if(res.data.code == "") {
            this.$message.warning("暂无建议竞价");
          }
          this.$set(this.keywordTableData[index], 'suggested', res.data.data[0].suggested);
          this.$set(this.keywordTableData[index], 'rangeEnd', res.data.data[0].rangeEnd);
          this.$set(this.keywordTableData[index], 'rangeStart', res.data.data[0].rangeStart);
        }else if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-select-select {
        margin-right: 20px;
        margin-bottom: 20px;
        width: 200px;
      }
      .el-input {
        width: 230px;
        margin-right: 20px;
      }
      .el-table {
        margin-top: 30px;
      }
      .el-pagination {
        padding-top: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
.styleClass {
  font-size: 16px;
  color: 	#808080;
}
.header-main-title {
  font-size: 18px;
}
.keywordClass {
  margin-top: 20px;
  left: 150px;
  margin-bottom: 30px;
  width: 2600px;
  height: 650px;
  border-radius: 20px;
}
</style>